<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_4">
      <router-link to="/">
        <img src="@/assets/images/m_header_logo_none.png" alt="" class="logo"
      /></router-link>
      <router-link to="" class="m_menu" @click.native="openMenu"
        ><img src="@/assets/images/menu_on.png" alt=""
      /></router-link>
    </div>
    <div
      id="m_gnb"
      class="slide"
      :style="open ? 'display:block' : 'display:none'"
    >
      <router-link to="" class="m_close" @click.native="openMenu"
        ><img src="@/assets/images/menu_on.png" alt=""
      /></router-link>
      <div class="m_gnb_top">
        <img src="@/assets/images/m_header_logo.png" alt="" />
      </div>
      <div class="m_gnb_wrap">
        <h3>
          <router-link to="" @click.native="movePage('info')"
            >(주)리뷰앤메이크머니</router-link
          >
        </h3>
        <h4>
          <router-link to="" @click.native="movePage('info', 'tab1')">
            - 진실한 리뷰의 가치를 아는 기업</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="movePage('info', 'tab2')">
            - 모든 리뷰는 동등한 가치를 지닐까요?</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="movePage('info', 'tab3')">
            - 선순환의 생태계에 동참하세요!</router-link
          >
        </h4>
        <p>
          <router-link to="" @click.native="movePage('info', 'tab4')"
            >· 리뷰어로서</router-link
          >
        </p>
        <p>
          <router-link to="" @click.native="movePage('info', 'tab5')"
            >· 제휴사로서</router-link
          >
        </p>
        <p>
          <router-link to="" @click.native="movePage('info', 'tab6')"
            >· 리뷰 이용자로서</router-link
          >
        </p>
        <h3>
          <router-link to="" @click.native="movePage('business')">
            리뷰앤메이크머니와 사업하기</router-link
          >
        </h3>
        <h4>
          <router-link to="" @click.native="movePage('business', 'tab1')"
            >- 협업의 기본구조</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="movePage('business', 'tab2')"
            >- 제휴사 신청 안내</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="$toast.default('준비중 입니다.')"
            >- 제휴사 신청 등록</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="$toast.default('준비중 입니다.')"
            >- 검토현황 조회</router-link
          >
        </h4>
        <h4>
          <router-link to="" @click.native="$toast.default('준비중 입니다.')"
            >- 리뷰거래소</router-link
          >
        </h4>
        <h3>
          <router-link to="" @click.native="movePage('terms')"
            >이용약관</router-link
          >
        </h3>
        <h3>
          <router-link to="" @click.native="movePage('privacy')"
            >개인정보처리방침</router-link
          >
        </h3>
        <h3>
          <router-link to="/cs/notice">고객센터</router-link>
        </h3>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <router-view ref="inner_view"></router-view>
    <!---->
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });
  },
  methods: {
    movePage(page, tab = null) {
      this.open = false;

      if (this.$route.path !== `/introduce/${page}`) {
        this.onoff();
        this.$router.push(`${page}${tab ? "?tab=" + tab : ""}`).catch(() => {});
      } else if (this.$route.path === `/introduce/${page}` && !tab) {
        this.onoff();
      } else {
        if (tab) {
          this.onoff().then(() => {
            document.querySelector(`#${tab}`).scrollIntoView();
          });
        }
      }
    },
    openMenu() {
      this.open = !this.open;
      this.onoff();
    },
    onoff() {
      return new Promise((resolve, reject) => {
        const menu = document.querySelector("#m_gnb");
        if (this.open) {
          document.querySelector("body").style.overflow = "hidden";
        } else {
          document.querySelector("body").style.overflow = "";
        }
        return resolve();
      });
    },
  },
};
</script>

<style lang="scss">
.slide {
  animation-duration: 0.3s;
  animation-name: "slide";
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: "slide";
}
@keyframes slide {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
@-webkit-keyframes slide {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
</style>
